<template>
    <div class="lineManagement">
        <jy-query ref="checkForm" :model="formInline">
            <jy-query-item label="定点编号:" prop="pointNo">
                <el-input v-model="formInline.pointNo" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="定点名称:" prop="name">
                <el-input v-model="formInline.name" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="定点类型:" prop="type">
                <el-select v-model="formInline.type" placeholder="请选择">
                    <el-option v-for="item in lboptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('reset')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="add" v-if="btnexist('insert')">新增</el-button>
                    <el-button type="primary" @click="batchdelete" v-if="btnexist('batchDelete')">批量删除</el-button>
                    <el-button type="primary" @click="onexport" v-if="btnexist('export')">导出</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="lists" tooltip-effect="dark" @select-all="selectallchange" @select="selectchange">
            <jy-table-column type="index" width="60" label="序号"> </jy-table-column>
            <jy-table-column type="selection" width="40"> </jy-table-column>
            <jy-table-column prop="pointNo" label="定点编号" min-width="100"> </jy-table-column>
            <jy-table-column prop="name" label="定点名称" min-width="100"> </jy-table-column>
            <jy-table-column prop="alias" label="别名" min-width="100"> </jy-table-column>
            <jy-table-column label="定点类型" min-width="100">
                <template slot-scope="scope"> {{scope.row.type|type}}</template>
            </jy-table-column>
            <jy-table-column prop="routeName" label="经停线路" min-width="100"> </jy-table-column>
            <jy-table-column prop="createTime" label="创建日期" min-width="160"> </jy-table-column>
            <jy-operate v-bind:list="operateList" width="160"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-dialog ref="dialog" @updateList="getlist" :lboptions="lboptions"></add-dialog>
    </div>
</template>
<script>
const lboptions = [{
        value: '',
        label: '全部',
    },
    {
        value: '0',
        label: '停车场',
    },
    {
        value: '1',
        label: '加油场站',
    },
    {
        value: '2',
        label: '维修场站',
    },
    {
        value: '3',
        label: '拐弯点',
    },
    {
        value: '4',
        label: '安全事故黑点',
    },
    {
        value: '5',
        label: '隧道',
    },
];
import adddialog from '@/components/pages/admin/basicInformation/fixedPointManagement/addfixedPoint'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formInline: {
                pointNo: '',
                name: '',
                type: '',
            },
            lists: [],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],

            lboptions,

            btnRoute:'/admin/basicInformation/fixedPointManagement',
        }
    },
    mixins:[btnMixins],
    components: {
        'add-dialog': adddialog,
    },
    activated() {
        this.getlist()
    },
    created() {
        this.setOperateList()
    },
    filters: {
        type: function(val) {
            let v = ''
            lboptions.forEach(one => {
                if (one.value == val) {
                    v = one.label
                }
            })
            return v
        },
    },
    methods: {
        setOperateList() {
            let l = [{
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                    isShow:()=>{
                        return this.btnexist('update')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('delete')
                    },
                },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/baseinfopoint/list'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },
        deleteRecord(brandIdAry) {
            this.$http.post('/baseinfopoint/delete', brandIdAry).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.getlist()
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 导出
        onexport() {
            let url = '/baseinfopoint/export'
            let option = {
                ...this.formInline,
            }
            this.$http.post(url, option, { isExcel: true }).then(({data}) => {
                this.$util.funDownload(data, '定点管理')
            })
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },
        // 批量删除
        batchdelete() {
            if (!this.batchdeleteList.length) {
                this.$message({
                    type: 'warning',
                    message: '请先选择删除项',
                })
                return false
            }
            this.$confirm('是否删除选中数据?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    let option = []
                    this.batchdeleteList.forEach(item => {
                        option.push(item.id)
                    })
                    this.deleteRecord(option)
                })
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该SIM卡?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    let params = []
                    params.push(row.id)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}

</script>